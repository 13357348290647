<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              class="login-logo"
              :src="imgUrl"
              alt="Login"
          />
        </div>
      </b-col>
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
          >
            Hoşgeldiniz! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            E-mail veya cep telefonu numaranız ile sisteme giriş yapabilirsiniz.
          </b-card-text>
          <validation-observer
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
            >
              <b-form-group
                  label="E-mail veya cep telefonu"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                >
                  <b-form-input
                      id="login-email"
                      v-model="account"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="E-mail veya cep telefonu"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Şifre</label>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="errorMsg">{{ errorMsg }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading"
              >
                Giriş
                <b-spinner small :class="{'d-none':!loading}"/>
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {getHomeRouteForLoggedInUser} from '@/auth/utils'

import abilities from '@/auth/abilities'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      account: '',
      sideImg: require('@/assets/images/pages/logo_fuscha.svg'),
      errorMsg: null,

      // validation rules
      required,
      email,
      abilities,
      loading: false
    }
  },
  watch: {
    account() {
      this.errorMsg = null
    },
    password() {
      this.errorMsg = null
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/logo_white.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      const t = this;
      if (t.loading) {
        return
      }
      this.$refs.loginForm.validate()
          .then(success => {
            if (success) {
              this.loading = true
              this.errorMsg = null
              useJwt.login({
                account: this.account,
                password: this.password,
              })
                  .then(response => {
                    this.loading = false
                    if (response.data.status) {
                      const allowedTypes = ['vet', 'petshop', 'groomer', 'admin']
                      const {data} = response.data
                      const {user} = data
                      if (!allowedTypes.includes(user.role)) {
                        this.errorMsg = 'Giriş yetkiniz bulunmamaktadır.'
                        return
                      }
                      useJwt.setToken(data.token)
                      user.ability = t.abilities[user.role];
                      localStorage.setItem('userData', JSON.stringify(user))

                      this.$ability.update(t.abilities[user.role])

                      this.$router.replace(getHomeRouteForLoggedInUser(user.role))
                          .then(() => {
                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                title: `Hoşgeldiniz ${user.fullName || user.email}`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `Başarıyla giriş yaptınız.`,
                              },
                            })
                          })
                    } else {
                      this.errorMsg = response.data.message
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.login-logo {
  width: 100%;
  max-width: 450px;
}
</style>
