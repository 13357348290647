export default {
    'owner': [

        {
            action: 'read',
            subject: 'Auth',
        },
    ],
    'vet': [



        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'vet',
        },
        {
            action: 'manage',
            subject: 'products',
        },
        {
            action: 'manage',
            subject: 'company',
        },
        {
            action: 'manage',
            subject: 'orders',
        },
        {
            action: 'manage',
            subject: 'services',
        },
        {
            action: 'manage',
            subject: 'expertises',
        },
    ],
    'petshop': [

        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'petshop',
        },
        {
            action: 'manage',
            subject: 'products',
        },
        {
            action: 'manage',
            subject: 'company',
        },
        {
            action: 'manage',
            subject: 'orders',
        },
        {
            action: 'manage',
            subject: 'services',
        },
        {
            action: 'manage',
            subject: 'expertises',
        },
    ],
    'pethotel': [

        {
          action: 'read',
          subject: 'Auth',
        },
    ],
    'restaurant': [

        {
          action: 'read',
          subject: 'Auth',
        },
    ],
    'petschool': [

        {
          action: 'read',
          subject: 'Auth',
        },
    ],
    'hotel': [

        {
          action: 'read',
          subject: 'Auth',
        },
    ],
    'shelter': [

        {
          action: 'read',
          subject: 'Auth',
        },
    ],
    'groomer': [

        {
          action: 'read',
          subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'groomer',
        },
        {
            action: 'manage',
            subject: 'products',
        },
        {
            action: 'manage',
            subject: 'orders',
        },
    ],
    'admin': [

        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'admin',
        },
        {
            action: 'manage',
            subject: 'admin_services',
        },
        {
            action: 'manage',
            subject: 'blog',
        },
        {
            action: 'manage',
            subject: 'product',
        },
        {
            action: 'manage',
            subject: 'settings',
        },
        {
            action: 'manage',
            subject: 'pettype',
        },
        {
            action: 'manage',
            subject: 'admin_product',
        },
        {
            action: 'manage',
            subject: 'faq',
        },
        {
            action: 'manage',
            subject: 'question_suggestion',
        },
        {
            action: 'manage',
            subject: 'users',
        },
        {
            action: 'manage',
            subject: 'membership_types',
        },
    ]
}
